/* HEADINGS */
@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@300;400;500;600;700;800;900&display=swap');

@font-face {
    font-family: 'IntegralCF';
    src: url('./los-assets/fonts/IntegralCF/IntegralCF-Regular.woff') format('woff');
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: 'IntegralCF';
    src: url('./los-assets/fonts/IntegralCF/IntegralCF-Medium.woff') format('woff');
    font-style: normal;
    font-weight: 500;
}

@font-face {
    font-family: 'IntegralCF';
    src: url('./los-assets/fonts/IntegralCF/IntegralCF-DemiBold.woff') format('woff');
    font-style: normal;
    font-weight: 600;
}

@font-face {
    font-family: 'IntegralCF';
    src: url('./los-assets/fonts/IntegralCF/IntegralCF-Bold.woff') format('woff');
    font-style: normal;
    font-weight: 700;
}

@font-face {
    font-family: 'IntegralCF';
    src: url('./los-assets/fonts/IntegralCF/IntegralCF-ExtraBold.woff') format('woff');
    font-style: normal;
    font-weight: 800;
}

@font-face {
    font-family: 'IntegralCF';
    src: url('./los-assets/fonts/IntegralCF/IntegralCF-Heavy.woff') format('woff');
    font-style: normal;
    font-weight: 900;
}

/* BODY */

@font-face {
    font-family: 'MadeOuterSans';
    src: url('./los-assets/fonts/MadeOuterSans/MADEOuterSansThin.woff') format('woff');
    font-style: normal;
    font-weight: 200;
}

@font-face {
    font-family: 'MadeOuterSans';
    src: url('./los-assets/fonts/MadeOuterSans/MADEOuterSansLight.woff') format('woff');
    font-style: normal;
    font-weight: 300;
}

@font-face {
    font-family: 'MadeOuterSans';
    src: url('./los-assets/fonts/MadeOuterSans/MADEOuterSansRegular.woff') format('woff');
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: 'MadeOuterSans';
    src: url('./los-assets/fonts/MadeOuterSans/MADEOuterSansMedium.woff') format('woff');
    font-style: normal;
    font-weight: 500;
}

@font-face {
    font-family: 'Raleway';
    src: url('./los-assets/fonts/MadeOuterSans/MADEOuterSansBlack.woff') format('woff');
    font-style: normal;
    font-weight: 600;
}

@font-face {
    font-family: 'MadeOuterSans';
    src: url('./los-assets/fonts/MadeOuterSans/MADEOuterSansBlack.woff') format('woff');
    font-style: normal;
    font-weight: 700;
}

/* ===== Scrollbar CSS ===== */
  /* Firefox */
  * {
    scrollbar-width: auto;
    scrollbar-color: #9e9e9e #707070;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 12px;
  }

  *::-webkit-scrollbar-track {
    background: #707070;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #9e9e9e;
    border-radius: 8px;
    border: 2px solid #8c8c8c;
  }

.mainGradient{
    -webkit-mask-image: linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,0));
    -webkit-mask-size: 100% 100%;
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-position: left top, left bottom;
}

.mainGradientBottom{
    -webkit-mask-image: linear-gradient(to top, rgba(0,0,0,1), rgba(0,0,0,0));
    -webkit-mask-size: 100% 100%;
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-position: left bottom, left top;
}

.zIndex{
    z-index: 1;
}

[data-theme="el-drop"] .border, .border-t, .border-b, .border-l, .border-r, .border-y {
    border-color: #3f3f3f;
}

[data-theme="el-drop"] .btn {
    @apply border-primary;
}

[data-theme="lofi"] * {
    border-color: #ccc;
}

